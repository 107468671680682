<template>
	<div>
		<div class="newCategory" v-loading="loading">


			<div class="game_list_wrap">
				<div class="content">


					<el-row class="title" v-loading="loading_list">
						<el-col :span="18">
							<el-tabs v-model="activeName" @tab-click="handleClick">
								<el-tab-pane label="所有游戏" name="gametab_999">

								</el-tab-pane>
								<!-- 动态分类 -->
								<el-tab-pane :label="item.category_name" :name="'gametab_'+index"
									v-for="(item, index) in goodsCategoryTree" :key="index">
								</el-tab-pane>
							</el-tabs>
						</el-col>

						<el-col :span="6">
							<div style="margin-top: 5px;">
								<el-input placeholder="请输入搜索内容" v-model="key_value" class="input-with-select">
									<el-button slot="append" icon="el-icon-search" @click="getCategoryConfig()">
									</el-button>
								</el-input>
							</div>
						</el-col>
					</el-row>


					<div class="content_list">
						<div v-if="fenlei_index==0" @click="goToGoodsListPage(item_0)" class="right"
							v-for="(item_0, index_0) in goodsCategoryAll" :key="index_0">
							<el-image :src="$img(item_0.image)"></el-image>
							<p>{{item_0.category_name}}</p>
						</div>
					</div>

					<div class="content_list">
						<div v-if="fenlei_index==(index+1)"  v-for="(item, index) in goodsCategoryTree" :key="index">
							<div @click="goToGoodsListPage(item_1)" class="right"
								v-for="(item_1, index_1) in item.child_list" :key="index_1">
								<el-image :src="$img(item_1.image)"></el-image>
								<p>{{item_1.category_name}}</p>
							</div>
						</div>
					</div>


					<!-- 使用tabs来区分 分类 -->



					<!-- <div class="word-content">
						<div class="en-word-search">
							<span class="en-word">全部</span>
							<span class="en-word">A</span>
							<span class="en-word">B</span>
							<span class="en-word">C</span>
							<span class="en-word">D</span>
							<span class="en-word">E</span>
							<span class="en-word">F</span>
							<span class="en-word">G</span>
							<span class="en-word">H</span>
							<span class="en-word">I</span>
							<span class="en-word">J</span>
							<span class="en-word">K</span>
							<span class="en-word">L</span>
							<span class="en-word">M</span>
							<span class="en-word">N</span>
							<span class="en-word">O</span>
							<span class="en-word">P</span>
							<span class="en-word">R</span>
							<span class="en-word">S</span>
							<span class="en-word">T</span>
							<span class="en-word">U</span>
							<span class="en-word">V</span>
							<span class="en-word">W</span>
							<span class="en-word">X</span>
							<span class="en-word">Y</span>
							<span class="en-word">Z</span>
						</div>
					</div> -->



					<!-- <div class="content_list">
						<div class="right" v-for="n in 30">
							<el-image
								src="https://zuiwangjia-images.oss-cn-chengdu.aliyuncs.com/game/20211102/mBXWhb_1635844086100.png">
							</el-image>
							<p>英雄联盟{{n}}</p>
						</div>
					</div> -->
				</div>


			</div>


			<!--全部分类-->
			<!-- <div class="categoryLink">
			    <ul id="categoryUl" :class="categoryFixed == true ? 'category-fixed' : ''">
			        <li v-for="(item, index) in goodsCategory" :key="index" :class="index == clickIndex ? 'selected' : ''" @click="changeCate(index, '#category' + index)">
			            <a>
			                <span>{{ item.category_name }}</span>
			            </a>
			        </li>
			    </ul>
			</div> -->


			<!-- <div class="categoryCon">
				<div :id="'category' + index" :ref="'category' + index" class="items" :class="'items-' + index"
					v-for="(cate1, index) in goodsCategory" :key="index">
					<h2>
						<router-link
							:to="{ path: '/list', query: { category_id: cate1.category_id, level: cate1.level } }"
							target="_blank">{{ cate1.category_name }}</router-link>
					</h2>

					<dl v-for="(cate2, index) in cate1.child_list" :key="index">
						<dt>
							<router-link
								:to="{ path: '/list', query: { category_id: cate2.category_id, level: cate2.level } }"
								target="_blank">
								{{ cate2.category_name }}
							</router-link>
						</dt>
						<dd>
							<router-link v-for="(cate3, index) in cate2.child_list" :key="index"
								:to="{ path: '/list', query: { category_id: cate3.category_id, level: cate3.level } }"
								target="_blank">
								{{ cate3.category_name }}
							</router-link>
						</dd>
					</dl>
				</div>
			</div> -->

			<div class="empty-wrap" v-if="goodsCategoryTree.length <= 0">
				<div class="ns-text-align">暂无游戏类目</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		tree,
		categoryConfig
	} from "@/api/goods/goodscategory"

	export default {
		name: "category",
		components: {},
		data: () => {
			return {
				goodsCategory: [],
				categoryFixed: false,
				clickIndex: 0,
				loading: true,
				goodsCategoryTree_int: [], //初始化数据
				goodsCategoryAll_int: [], //初始化数据
				goodsCategoryTree: [],
				goodsCategoryAll: [],
				key_value: '',
				activeName: 'gametab_999',
				loading_list: true,
				fenlei_index:0,
			}
		},
		created() {
			this.getGoodsCategory();
			this.getCategoryConfig(); //分类获取
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll)
		},
		methods: {
			// 分类列表
			getGoodsCategory() {
				tree({
						level: 3,
						template: 2
					})
					.then(res => {
						if (res.code == 0) {
							this.goodsCategory = res.data
						}
						this.loading = false
					})
					.catch(err => {
						this.$message.error(err.message)
						this.loading = false
					})
			},
			// 监听滚动条
			handleScroll() {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				var offsetTop = document.querySelector(".newCategory").offsetTop

				if (scrollTop > offsetTop) {
					this.categoryFixed = true
				} else {
					this.categoryFixed = false
				}

				var divTopArr = []
				for (let i = 0; i < this.goodsCategory.length; i++) {
					var _top = this.$refs["category" + i][0].offsetTop

					divTopArr.push(_top)
					var _offset = scrollTop - offsetTop
					if (_offset < divTopArr[divTopArr.length - 1]) {
						if (_offset >= divTopArr[i] && _offset < divTopArr[i + 1]) {
							this.clickIndex = i
						}
					} else {
						this.clickIndex = divTopArr.length - 1
					}
				}
			},
			// 点击左侧分类
			changeCate(index, obj) {
				this.clickIndex = index
				document.querySelector(obj).scrollIntoView(true)
			},


			/**
			 * 获取分类
			 */
			// 获取配置
			getCategoryConfig() {
				this.loading_list = true;
				categoryConfig({

					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.categoryConfig = res.data;
							this.getTree(res.data);
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},

			goToGoodsListPage(item) {
				this.$router.push({
					path: "/list",
					query: {
						category_id: item.category_id,
						level: item.level
					}
				})
			},

			getTree(categoryConfig) {
				tree({
						level: 3,
						template: 2,
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							var child_data = [];
							var key_value = this.key_value;
							this.goodsCategoryTree = res.data || [];
							for (let i = 0; i < this.goodsCategoryTree.length; i++) {
								// 大于三层不显示
								if (this.goodsCategoryTree[i].child_list > 3) {
									this.isHide = true
								}
								if (this.goodsCategoryTree[i].child_list) {
									let new_child_list = [];
									for (var j = 0; j < this.goodsCategoryTree[i].child_list.length; j++) {
										// if(this.goodsCategoryTree[i].child_list[j].is_show==1){
										// child_data.push(this.goodsCategoryTree[i].child_list[j]);
										// }
										// 加入关键字查询
										if (this.goodsCategoryTree[i].child_list[j].category_name.includes(
											key_value)) {
											new_child_list.push(this.goodsCategoryTree[i].child_list[j]);
											child_data.push(this.goodsCategoryTree[i].child_list[j]);
										}
									}
									this.goodsCategoryTree[i].child_list = new_child_list;
								}

							}
							this.goodsCategoryAll = child_data;
							this.loading_list = false;
						}
					})
					.catch(err => {
						this.loading_list = false;
						this.$message.error(err.message);
					});
			},

			handleClick(e) {
				this.fenlei_index=e.index;
			},

		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		}
	}
</script>
<style lang="scss" scoped>
	.newCategory {
		width: $width;
		margin: 0 auto;
		overflow: hidden;
		background-color: #fff;

		.categoryLink {
			padding-top: 10px;
			float: left;
			position: relative;
			width: 210px;

			ul {
				width: 210px;
				padding-top: 20px;
				background-color: #FFFFFF;

				li {
					width: 200px;
					height: 30px;
					text-align: left;
					background-color: #f5f5f5;
					border-radius: 30px;
					color: #333;
					line-height: 30px;
					overflow: hidden;
					position: relative;
					cursor: pointer;
					margin: 0 auto 15px;

					&.selected {
						background-color: $base-color;
						background: -moz-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
						background: -webkit-gradient(45deg, color-stop(0%, $base-color), color-stop(100%, #ffffff));
						background: -webkit-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
						background: -o-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
						background: -ms-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
						background: linear-gradient(45deg, $base-color 0%, #ffffff 100%);

						a {
							color: #fff;
						}
					}

					a {
						display: block;
						margin-left: 30px;
					}
				}
			}
		}

		.category-fixed {
			position: fixed;
			top: 0;
			z-index: 99;
		}

		.categoryCon {
			float: left;
			padding: 0px 0 60px;
			overflow: hidden;
			width: 990px;
			position: relative;

			.items {
				padding-left: 40px;

				h2 {
					font-size: 18px;
					font-weight: 600;
					line-height: 40px;
					margin-top: 30px;
					border-bottom: 1px solid transparent;
				}

				dl {
					padding: 15px 0 5px;
					border-bottom: 1px solid #efefef;
					overflow: hidden;

					dt {
						padding-right: 20px;
						width: 72px;
						position: relative;
						background: #fff;
						float: left;

						a {
							float: left;
							max-width: 72px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							font-weight: 600;
						}
					}

					dd {
						float: left;
						width: 858px;

						a {
							color: #666;
							float: left;
							padding: 0 12px;
							margin: 0 0 10px -1px;
							border-left: 1px solid #e0e0e0;
							white-space: nowrap;
						}
					}
				}
			}
		}

		/**
		 * 新增颜色
		 * 竖着排序
		 */
		.game_list_wrap {
			width: 100%;
			height: auto;
			min-height: 600px;

			.content {
				width: $width;
				height: auto;
				margin: 0 auto;
				margin-top: 20px;
				background-color: #fff;

				.title {
					padding: 10px;
				}

				.content_list {
					width: $width;
					height: auto;
					margin: 0 auto;
					// margin-top: 20px;
					border-radius: 10px;
					padding-top: 0px;
					// box-sizing: border-box;
					display: flex;
					// white-space: nowrap;
					flex-wrap: wrap;
					background-color: #fff;

					.right {
						width: 110px;
						text-align: center;
						display: inline-block;
						margin-top: 15px;
						cursor: pointer;

						.el-image {
							width: 85px;
							height: 85px;
							line-height: 90px;
							text-align: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						p {
							font-size: 14px;
							color: #838383;
						}
					}
				}


				.word-content {
					width: 95%;
					padding: 10px;

					.en-word-search {
						width: 100%;
						height: 25px;
						padding: 7px 20px;
						background-color: #f7f7f7;
						color: #aaa;
						font-size: 14px;
						font-weight: 400;
						justify-content: space-between;

						.en-word {
							margin-right: 32px;
							cursor: pointer;
						}
					}
				}

			}
		}
	}
</style>

<style lang="scss">
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}



	.el-tabs {
		.el-tabs__header {
			.el-tabs__nav-wrap {
				.el-tabs__nav-scroll {
					.el-tabs__nav {
						.el-tabs__active-bar {
							display: none;
						}

						.el-tabs__item {
							width: 120px;
							height: 50px;
							padding: 0;
							text-align: center;
							line-height: 50px;
							font-size: 20px;
							color: $base-color-info;
							// border-radius: 16px;
						}

						.el-tabs__item.is-active {
							font-size: 22px;
							color: $base-color;
							border-bottom: 3px solid $base-color;
							// color: #ffffff;
							// background: linear-gradient(45deg, $base-color 0%, #F47039 99%);
						}
					}
				}
			}
		}

		.el-tabs__nav-wrap::after {
			display: none;
		}

		.more_info {
			color: $base-color-info;
			width: 100%;
			text-align: right;
			height: 50px;
			line-height: 50px;
			font-size: 16px;
			cursor: pointer;
		}
	}
</style>
